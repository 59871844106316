import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from 'src/utils/auth';

interface OwnerRouteProps {
  children: React.ReactElement;
}

const OwnerRoute: React.FC<OwnerRouteProps> = ({ children }) => {
  const { isOwner, isLogged } = useAuth();

  if (!isLogged) {
    return <Navigate to="/signin" replace />;
  }

  if (!isOwner) {
    return <Navigate to="/enterprise" replace />;
  }

  return children;
};

export default OwnerRoute;