import { ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "src/utils/auth";
import SuspenseLoader from "src/components/common/Loaders/SuspenseLoader";

type Props = {
  children: ReactNode;
  redirectTo?: string;
};

export const SubscriptionPlanMiddleware = ({ children, redirectTo }: Props) => {
  const navigate = useNavigate();
  const { handleInitUser, isSubscribed, isSuperUser, activePlanId } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        await handleInitUser();
      } catch (error) {
        console.error('Erro ao verificar status de assinatura:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkSubscriptionStatus();
  }, [handleInitUser]);

  if (isLoading) {
    return <SuspenseLoader />;
  }

  if ((!isSubscribed || !activePlanId) && !isSuperUser) {
    navigate(redirectTo);
    return null;
  }

  return (
      <>{children}</>
    )};
