import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { useRequests } from './requests';
import { setUser, setUserEnterprise, setSubscriptionStatus, clearSubscriptionStatus } from './redux/reducers/authReducer';
import { useCallback } from 'react';

const LOCAL_STORAGE_KEY = 'AUTH_ACCESS';

export const handleGetAccessToken = () => localStorage.getItem(LOCAL_STORAGE_KEY) ?? '';

export const useAuth = () => {
  const auth = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const { signIn, getUser, getSubscriptionStatus } = useRequests();

  const user = {
    ...auth.user,
    enterprise: auth.enterprise,
  };

  const handleFetchSubscriptionStatus = useCallback(async () => {
    try {
      const subscriptionResponse = await getSubscriptionStatus();
      const { is_subscribed, active_plan_id } = subscriptionResponse.data || {};
      dispatch(setSubscriptionStatus({ isSubscribed: is_subscribed, activePlanId: active_plan_id }));
      return { isSubscribed: is_subscribed, activePlanId: active_plan_id };
    } catch (error) {
      console.error('Error getting subscription status:', error);
      return { isSubscribed: false, activePlanId: null };
    }
  }, [dispatch, getSubscriptionStatus]);

  const handleInitUser = useCallback(async () => {
    const access_token = handleGetAccessToken();
    if (!access_token) return;

    const response = await getUser();

    if (!response.detail) {
      dispatch(setUser(response.data.user));
      dispatch(setUserEnterprise(response.data.enterprise));

      const subscription = await handleFetchSubscriptionStatus();

      console.log(
        "User",
        response.data.enterprise,
        "Plan:",
        subscription.activePlanId,
        "Subscribed:",
        subscription.isSubscribed
      );

      await handleFetchSubscriptionStatus();
    }
  }, [dispatch, getUser, handleFetchSubscriptionStatus]);

  const handlePermissionExists = useCallback(
    (permissionCodename: string) => {
      if (auth.enterprise?.is_owner) return true;

      return auth.enterprise?.permissions?.some((p) => p.code_name === permissionCodename) ?? false;
    },
    [auth.enterprise]
  );

  const handleSignIn = async (email: string, password: string) => {
    const response = await signIn({ email, password });

    if (!response.detail) {
      dispatch(setUser(response.data.user));
      dispatch(setUserEnterprise(response.data.enterprise));

      localStorage.setItem(LOCAL_STORAGE_KEY, response.data.access);

      await handleFetchSubscriptionStatus();
    }

    return response;
  };

  const handleSignOut = () => {
    dispatch(setUser(null));
    dispatch(setUserEnterprise(null));
    dispatch(clearSubscriptionStatus());

    localStorage.removeItem(LOCAL_STORAGE_KEY);
  };

  return {
    user,
    isLogged: auth.user != null,
    isSubscribed: auth.isSubscribed,
    activePlanId: auth.activePlanId,
    handleInitUser,
    handlePermissionExists,
    handleSignIn,
    handleSignOut,
    handleFetchSubscriptionStatus,
    accessToken: handleGetAccessToken(),
    isOwner: auth.enterprise?.is_owner || false,
    isSuperUser: auth.user?.is_superuser || false,
  };
};