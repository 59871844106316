import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User, UserEnterpriseDetail } from 'src/models/Auth';

interface AuthState {
  user: User | null;
  enterprise: UserEnterpriseDetail | null;
  isSubscribed: boolean;
  activePlanId: string | null;
}

const initialState: AuthState = {
  user: null,
  enterprise: null,
  isSubscribed: false,
  activePlanId: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setUserEnterprise: (state, action: PayloadAction<UserEnterpriseDetail>) => {
      state.enterprise = action.payload;
    },
    setSubscriptionStatus: (state, action: PayloadAction<{ isSubscribed: boolean; activePlanId: string | null }>) => {
      state.isSubscribed = action.payload.isSubscribed;
      state.activePlanId = action.payload.activePlanId;
    },
    clearSubscriptionStatus: (state) => {
      state.isSubscribed = false;
      state.activePlanId = null;
    },
  },
});

export const { setUser, setUserEnterprise, setSubscriptionStatus, clearSubscriptionStatus } = authSlice.actions;

export default authSlice.reducer;
