import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from 'src/utils/auth';

interface Props {
    children: React.ReactElement;
}

const SuccessRouteMiddleware: React.FC<Props> = ({ children }) => {
    const { isLogged, isSubscribed, isOwner } = useAuth();
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const sessionId = query.get('session_id');

    if (!sessionId) {
        return isLogged ? <Navigate to="/enterprise" replace /> : <Navigate to="/signin" replace />;
    }

    if (!isLogged || (!isSubscribed && !isOwner)) {
        return isLogged ? <Navigate to="/enterprise" replace /> : <Navigate to="/signin" replace />;
    }

    return children;
};

export default SuccessRouteMiddleware;
